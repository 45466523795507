
import { Component, Vue } from "vue-property-decorator";
import { $auth } from "@/pages/auth/services/auth.service";

@Component({
  name: "login"
})
export default class Login extends Vue {
  codeImageUrl = '';
  currentRole = $auth.currentRole;
  form = $auth.form;
  loading = false;
  isMounted = false;

  mounted() {
    this.refreshCode();
    setTimeout(() => {
      this.isMounted = true;
    }, 500);
  }

  refreshCode() {
    this.codeImageUrl = this.currentRole.getCodeImageUrl('LOGIN');
    this.$forceUpdate();
  }

  login(formRef) {
    formRef.validate(valid => {
      if (valid) {
        this.loading = true;
        $auth.login().then(() => {
          this.loading = false;
        }, () => {
          this.loading = false;
          this.refreshCode();
        });
      }
    });
  }
}
