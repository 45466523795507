import VueRouter from "vue-router";
import {v1} from 'uuid';
import {getValidateCodeUrl, ValidateCodeScene} from '@/pages/auth/api';

function getRoute() {
  const {app} = (window as any);
  return app.$route;
}

function getRouter() {
  const {app} = (window as any);
  return app.$router as VueRouter;
}

function getStore() {
  const {app} = (window as any);
  return app.$store;
}

const ROLE_ADMIN_UUID = 'role_admin_uuid';

export class AuthRole {
  uuid = '';

  constructor() {
    this.uuid = localStorage.getItem(ROLE_ADMIN_UUID) || (() => {
      const uuid = v1({msecs: Date.now() * Math.random()});
      localStorage.setItem(ROLE_ADMIN_UUID, uuid);
      return uuid;
    })();
  }

  getCodeImageUrl(key: ValidateCodeScene) {
    return getValidateCodeUrl(key, this.uuid);
  }

  login(username: string, password: string, captcha: string) {
    return new Promise<any>((resolve, reject) => {
      getStore().dispatch('loginAction', {
        uuid: this.uuid,
        username,
        password,
        captcha
      }).then(() => {
        const {forward} = getRoute().query;

        getRouter().replace({path: forward || '/'}).then(() => {
          resolve(true);
        });
      }, reject);
    });
  }
}

export class AuthService {
  currentRole = new AuthRole();

  form = {
    username: '',
    pwd: '',
    code: '',
    rules: {
      username: [
        {
          required: true,
          message: '请输入登录账户！',
        },
      ],
      pwd: [
        {
          required: true,
          message: '请输入登录密码！',
        },
      ],
      code: [
        {
          required: true,
          message: '请输入验证码！',
        },
      ],
    }
  };

  constructor() {
  }

  trimForm() {
    this.form.username = this.form.username.trim();
    this.form.pwd = this.form.pwd.trim();
    this.form.code = this.form.code.trim();
  }

  clearForm() {
    this.form.username = '';
    this.form.pwd = '';
    this.form.code = '';
  }

  login() {
    this.trimForm();
    return this.currentRole.login(
      this.form.username,
      this.form.pwd,
      this.form.code,
    );
  }
}

export const $auth = new AuthService();
